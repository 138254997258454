import React, { PropsWithChildren, ReactElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledAspectRatioWrapper = styled.div<{
  ratio: number
}>`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${({ ratio }) => `${(100 / ratio).toFixed(2)}%`};

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
/**
 * @deprecated
 * Use native `aspect-ratio` CSS property directly instead of this component.
 */
const AspectRatioWrapper = ({
  ratio = 16 / 9,
  className,
  children,
}: PropsWithChildren<{
  ratio: number | null
  className?: string
}>): ReactElement => (
  <StyledAspectRatioWrapper ratio={ratio || 16 / 9} className={className}>
    {children}
  </StyledAspectRatioWrapper>
)

AspectRatioWrapper.propTypes = {
  ratio: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
}

AspectRatioWrapper.defaultProps = {
  ratio: 16 / 9,
  children: null,
  className: '',
}

export default AspectRatioWrapper
